@import url('https://fonts.googleapis.com/css?family=Poppins');


body {
  font-family: 'Poppins', sans-serif;
}

.color-1 {
  background-color: #78d1f9;
  /* color: rgb(253, 253, 253); */
  font-weight: bold;
}

/* .padelzone>.table thead td, .table thead th {
  background-color: #45a4e8;
  color: rgb(253, 253, 253) !important;
  font-weight: bold;
} */

.poppins-italic {
  font-style: italic;
}

.poppins-bold {
  font-weight: 900;
}

.subtitle {
  font-size: 1.2em;
}

.red {
  background: red;
}

.img-responsive {
  max-width: 150px;
  position: absolute;
  left: 0;
  right: 0;
  top: 15%;
}

.color {
  color: #BDBDBD;
}

.icon-responsive {
  max-width: 6rem;
}

.gridHome {
  width: 35%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  text-align: center;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
  height: 40vh;
}

.gridRedes {
  width: 3%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin: auto;
  margin-top: 0rem;
  margin-bottom: 3rem;
  height: 0vh;
}

.gridPrograma {
  width: 55%;
  display: grid;
  grid-template-columns: 15% auto;
  column-gap: 1.2rem;
  row-gap: 2rem;
  text-align: start;
  margin: auto;
  margin-bottom: 2rem;
}

.size {
  font-size: 1rem;
}

.parrafo {
  font-size: 1rem;
}

.imgInformacion {
  max-width: 40rem;
}


.buttonUbicacion {
  width: 40%;
}

.mapIframe {
  max-width: 42%;
  margin: auto;
}

.imgIframe {
  width: 600px;
  height: 450px;
}

.margin {
  margin-top: 6rem;
}

.containerPanelista {
  width: 30%;
  margin: auto;
  box-shadow: 2px -1px 12px 0px rgb(82, 81, 81);
  padding-top: 3rem;
  margin-bottom: 3rem;
  border-radius: 2%;
}

.img_panelistas {
  max-width: 100px;
}

.main {
  padding: 0px;
  margin: 5px 10px;
}

/* css Olimpiadas */

.title-home {
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
}

/* setup */
html,
body,
.holster {
  height: 100%;
}

.holster {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  font-family: monospace;
}

.containersnap {
  display: flex;
  overflow: auto;
  flex: none;
}

.containersnap.x {
  width: 95%;
  height: 185px;
  flex-flow: row nowrap;
}

.containersnap.y {
  width: 256px;
  height: 256px;
  flex-flow: column nowrap;
}

/* scroll-snap */
.x.mandatory-scroll-snapping {
  scroll-snap-type: x mandatory;
}

.y.mandatory-scroll-snapping {
  scroll-snap-type: y mandatory;
}

.x.proximity-scroll-snapping {
  scroll-snap-type: x proximity;
}

.y.proximity-scroll-snapping {
  scroll-snap-type: y proximity;
}

.containersnap>div {
  text-align: center;
  scroll-snap-align: center;
  flex: none;
}

.x.containersnap>div {
  line-height: 128px;
  font-size: 64px;
  width: 40%;
  height: 128px;
  
}

.y.containersnap>div {
  line-height: 256px;
  font-size: 128px;
  width: 256px;
  height: 100%;
}

/* appearance fixes */
.y.containersnap>div:first-child {
  line-height: 1.3;
  font-size: 64px;
}

/* This container class will allow a table to scroll horizontally on smaller screens. */
.hscroll {
  overflow-x: auto;
  /* Horizontal */
}


.contentbutton{
  text-align: center;
  padding-bottom: 10px;
}

/* MEDIA QUERIS*/

@media screen and (max-width: 42em) {
  .img-responsive {
    max-width: 200%;
    top: 20%;
  }

  .size {
    font-size: 0.7rem;
  }

  .parrafo {
    font-size: 0.9rem;
  }

  .margin {
    margin-top: 10rem;
  }

  .gridHome {
    width: 70%;
    margin-top: 4rem;
  }

  .gridRedes {
    width: 22%;
    margin-top: -2rem;
  }

  .gridPrograma {
    width: 90%;
  }

  .imgInformacion {
    max-width: 18rem;
  }

  .buttonUbicacion {
    width: 80%;
  }

  .mapIframe {
    max-width: 80%;
    margin: auto;
  }

  .imgIframe {
    width: 300px;
    height: 230px;
  }

  .margin {
    margin-top: 4rem;
  }

  .container {
    width: 80%;
    margin: auto
  }

  .img_panelistas {
    max-width: 60px;
  }

  .containerPanelista {
    width: 70%;
  }

}

/* Congreso 2023 CSS */


.sectionConstruction {
  font-size: 1.2em;
}

.titleConstruction {
  font-size: 2em;
  font-weight: bold;
  margin-top: 10px;
}

.headerConstrucion {
  background-image: url(./img/fondo-50.svg);
  background-repeat: none;
  background-size: cover;
  margin-top: -60px;
}

.titleHotel {
  color: #3EC3FF;
  font-size: 1.2em;
  font-weight: bold;
}


.icon-right{
  margin-top: 5px;
  padding-left: 5px;
  vertical-align: bottom;
}

.tab-principal{
  background: rgb(213, 207, 207);
  padding: 15px;
}
